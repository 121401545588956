// Copy of the ace-builds/webpack-resolver.js file with those 2 search and replace:
// * file-loader?esModule=false!./src-noconflict => ace-builds/src-noconflict
// * ')); => '))
import ace from 'ace-builds/src-noconflict/ace'

ace.config.setModuleUrl('ace/ext/beautify', require('ace-builds/src-noconflict/ext-beautify.js'))
ace.config.setModuleUrl('ace/ext/code_lens', require('ace-builds/src-noconflict/ext-code_lens.js'))
ace.config.setModuleUrl('ace/ext/command_bar', require('ace-builds/src-noconflict/ext-command_bar.js'))
ace.config.setModuleUrl('ace/ext/elastic_tabstops_lite', require('ace-builds/src-noconflict/ext-elastic_tabstops_lite.js'))
ace.config.setModuleUrl('ace/ext/emmet', require('ace-builds/src-noconflict/ext-emmet.js'))
ace.config.setModuleUrl('ace/ext/error_marker', require('ace-builds/src-noconflict/ext-error_marker.js'))
ace.config.setModuleUrl('ace/ext/hardwrap', require('ace-builds/src-noconflict/ext-hardwrap.js'))
ace.config.setModuleUrl('ace/ext/inline_autocomplete', require('ace-builds/src-noconflict/ext-inline_autocomplete.js'))
ace.config.setModuleUrl('ace/ext/keyboard_menu', require('ace-builds/src-noconflict/ext-keybinding_menu.js'))
ace.config.setModuleUrl('ace/ext/language_tools', require('ace-builds/src-noconflict/ext-language_tools.js'))
ace.config.setModuleUrl('ace/ext/linking', require('ace-builds/src-noconflict/ext-linking.js'))
ace.config.setModuleUrl('ace/ext/modelist', require('ace-builds/src-noconflict/ext-modelist.js'))
ace.config.setModuleUrl('ace/ext/options', require('ace-builds/src-noconflict/ext-options.js'))
ace.config.setModuleUrl('ace/ext/prompt', require('ace-builds/src-noconflict/ext-prompt.js'))
ace.config.setModuleUrl('ace/ext/rtl', require('ace-builds/src-noconflict/ext-rtl.js'))
ace.config.setModuleUrl('ace/ext/searchbox', require('ace-builds/src-noconflict/ext-searchbox.js'))
ace.config.setModuleUrl('ace/ext/settings_menu', require('ace-builds/src-noconflict/ext-settings_menu.js'))
ace.config.setModuleUrl('ace/ext/simple_tokenizer', require('ace-builds/src-noconflict/ext-simple_tokenizer.js'))
ace.config.setModuleUrl('ace/ext/spellcheck', require('ace-builds/src-noconflict/ext-spellcheck.js'))
ace.config.setModuleUrl('ace/ext/split', require('ace-builds/src-noconflict/ext-split.js'))
ace.config.setModuleUrl('ace/ext/static_highlight', require('ace-builds/src-noconflict/ext-static_highlight.js'))
ace.config.setModuleUrl('ace/ext/statusbar', require('ace-builds/src-noconflict/ext-statusbar.js'))
ace.config.setModuleUrl('ace/ext/textarea', require('ace-builds/src-noconflict/ext-textarea.js'))
ace.config.setModuleUrl('ace/ext/themelist', require('ace-builds/src-noconflict/ext-themelist.js'))
ace.config.setModuleUrl('ace/ext/whitespace', require('ace-builds/src-noconflict/ext-whitespace.js'))
ace.config.setModuleUrl('ace/keyboard/emacs', require('ace-builds/src-noconflict/keybinding-emacs.js'))
ace.config.setModuleUrl('ace/keyboard/sublime', require('ace-builds/src-noconflict/keybinding-sublime.js'))
ace.config.setModuleUrl('ace/keyboard/vim', require('ace-builds/src-noconflict/keybinding-vim.js'))
ace.config.setModuleUrl('ace/keyboard/vscode', require('ace-builds/src-noconflict/keybinding-vscode.js'))
ace.config.setModuleUrl('ace/mode/abap', require('ace-builds/src-noconflict/mode-abap.js'))
ace.config.setModuleUrl('ace/mode/abc', require('ace-builds/src-noconflict/mode-abc.js'))
ace.config.setModuleUrl('ace/mode/actionscript', require('ace-builds/src-noconflict/mode-actionscript.js'))
ace.config.setModuleUrl('ace/mode/ada', require('ace-builds/src-noconflict/mode-ada.js'))
ace.config.setModuleUrl('ace/mode/alda', require('ace-builds/src-noconflict/mode-alda.js'))
ace.config.setModuleUrl('ace/mode/apache_conf', require('ace-builds/src-noconflict/mode-apache_conf.js'))
ace.config.setModuleUrl('ace/mode/apex', require('ace-builds/src-noconflict/mode-apex.js'))
ace.config.setModuleUrl('ace/mode/applescript', require('ace-builds/src-noconflict/mode-applescript.js'))
ace.config.setModuleUrl('ace/mode/aql', require('ace-builds/src-noconflict/mode-aql.js'))
ace.config.setModuleUrl('ace/mode/asciidoc', require('ace-builds/src-noconflict/mode-asciidoc.js'))
ace.config.setModuleUrl('ace/mode/asl', require('ace-builds/src-noconflict/mode-asl.js'))
ace.config.setModuleUrl('ace/mode/assembly_x86', require('ace-builds/src-noconflict/mode-assembly_x86.js'))
ace.config.setModuleUrl('ace/mode/astro', require('ace-builds/src-noconflict/mode-astro.js'))
ace.config.setModuleUrl('ace/mode/autohotkey', require('ace-builds/src-noconflict/mode-autohotkey.js'))
ace.config.setModuleUrl('ace/mode/batchfile', require('ace-builds/src-noconflict/mode-batchfile.js'))
ace.config.setModuleUrl('ace/mode/bibtex', require('ace-builds/src-noconflict/mode-bibtex.js'))
ace.config.setModuleUrl('ace/mode/c9search', require('ace-builds/src-noconflict/mode-c9search.js'))
ace.config.setModuleUrl('ace/mode/c_cpp', require('ace-builds/src-noconflict/mode-c_cpp.js'))
ace.config.setModuleUrl('ace/mode/cirru', require('ace-builds/src-noconflict/mode-cirru.js'))
ace.config.setModuleUrl('ace/mode/clojure', require('ace-builds/src-noconflict/mode-clojure.js'))
ace.config.setModuleUrl('ace/mode/cobol', require('ace-builds/src-noconflict/mode-cobol.js'))
ace.config.setModuleUrl('ace/mode/coffee', require('ace-builds/src-noconflict/mode-coffee.js'))
ace.config.setModuleUrl('ace/mode/coldfusion', require('ace-builds/src-noconflict/mode-coldfusion.js'))
ace.config.setModuleUrl('ace/mode/crystal', require('ace-builds/src-noconflict/mode-crystal.js'))
ace.config.setModuleUrl('ace/mode/csharp', require('ace-builds/src-noconflict/mode-csharp.js'))
ace.config.setModuleUrl('ace/mode/csound_document', require('ace-builds/src-noconflict/mode-csound_document.js'))
ace.config.setModuleUrl('ace/mode/csound_orchestra', require('ace-builds/src-noconflict/mode-csound_orchestra.js'))
ace.config.setModuleUrl('ace/mode/csound_score', require('ace-builds/src-noconflict/mode-csound_score.js'))
ace.config.setModuleUrl('ace/mode/csp', require('ace-builds/src-noconflict/mode-csp.js'))
ace.config.setModuleUrl('ace/mode/css', require('ace-builds/src-noconflict/mode-css.js'))
ace.config.setModuleUrl('ace/mode/curly', require('ace-builds/src-noconflict/mode-curly.js'))
ace.config.setModuleUrl('ace/mode/cuttlefish', require('ace-builds/src-noconflict/mode-cuttlefish.js'))
ace.config.setModuleUrl('ace/mode/d', require('ace-builds/src-noconflict/mode-d.js'))
ace.config.setModuleUrl('ace/mode/dart', require('ace-builds/src-noconflict/mode-dart.js'))
ace.config.setModuleUrl('ace/mode/diff', require('ace-builds/src-noconflict/mode-diff.js'))
ace.config.setModuleUrl('ace/mode/django', require('ace-builds/src-noconflict/mode-django.js'))
ace.config.setModuleUrl('ace/mode/dockerfile', require('ace-builds/src-noconflict/mode-dockerfile.js'))
ace.config.setModuleUrl('ace/mode/dot', require('ace-builds/src-noconflict/mode-dot.js'))
ace.config.setModuleUrl('ace/mode/drools', require('ace-builds/src-noconflict/mode-drools.js'))
ace.config.setModuleUrl('ace/mode/edifact', require('ace-builds/src-noconflict/mode-edifact.js'))
ace.config.setModuleUrl('ace/mode/eiffel', require('ace-builds/src-noconflict/mode-eiffel.js'))
ace.config.setModuleUrl('ace/mode/ejs', require('ace-builds/src-noconflict/mode-ejs.js'))
ace.config.setModuleUrl('ace/mode/elixir', require('ace-builds/src-noconflict/mode-elixir.js'))
ace.config.setModuleUrl('ace/mode/elm', require('ace-builds/src-noconflict/mode-elm.js'))
ace.config.setModuleUrl('ace/mode/erlang', require('ace-builds/src-noconflict/mode-erlang.js'))
ace.config.setModuleUrl('ace/mode/flix', require('ace-builds/src-noconflict/mode-flix.js'))
ace.config.setModuleUrl('ace/mode/forth', require('ace-builds/src-noconflict/mode-forth.js'))
ace.config.setModuleUrl('ace/mode/fortran', require('ace-builds/src-noconflict/mode-fortran.js'))
ace.config.setModuleUrl('ace/mode/fsharp', require('ace-builds/src-noconflict/mode-fsharp.js'))
ace.config.setModuleUrl('ace/mode/fsl', require('ace-builds/src-noconflict/mode-fsl.js'))
ace.config.setModuleUrl('ace/mode/ftl', require('ace-builds/src-noconflict/mode-ftl.js'))
ace.config.setModuleUrl('ace/mode/gcode', require('ace-builds/src-noconflict/mode-gcode.js'))
ace.config.setModuleUrl('ace/mode/gherkin', require('ace-builds/src-noconflict/mode-gherkin.js'))
ace.config.setModuleUrl('ace/mode/gitignore', require('ace-builds/src-noconflict/mode-gitignore.js'))
ace.config.setModuleUrl('ace/mode/glsl', require('ace-builds/src-noconflict/mode-glsl.js'))
ace.config.setModuleUrl('ace/mode/gobstones', require('ace-builds/src-noconflict/mode-gobstones.js'))
ace.config.setModuleUrl('ace/mode/golang', require('ace-builds/src-noconflict/mode-golang.js'))
ace.config.setModuleUrl('ace/mode/graphqlschema', require('ace-builds/src-noconflict/mode-graphqlschema.js'))
ace.config.setModuleUrl('ace/mode/groovy', require('ace-builds/src-noconflict/mode-groovy.js'))
ace.config.setModuleUrl('ace/mode/haml', require('ace-builds/src-noconflict/mode-haml.js'))
ace.config.setModuleUrl('ace/mode/handlebars', require('ace-builds/src-noconflict/mode-handlebars.js'))
ace.config.setModuleUrl('ace/mode/haskell', require('ace-builds/src-noconflict/mode-haskell.js'))
ace.config.setModuleUrl('ace/mode/haskell_cabal', require('ace-builds/src-noconflict/mode-haskell_cabal.js'))
ace.config.setModuleUrl('ace/mode/haxe', require('ace-builds/src-noconflict/mode-haxe.js'))
ace.config.setModuleUrl('ace/mode/hjson', require('ace-builds/src-noconflict/mode-hjson.js'))
ace.config.setModuleUrl('ace/mode/html', require('ace-builds/src-noconflict/mode-html.js'))
ace.config.setModuleUrl('ace/mode/html_elixir', require('ace-builds/src-noconflict/mode-html_elixir.js'))
ace.config.setModuleUrl('ace/mode/html_ruby', require('ace-builds/src-noconflict/mode-html_ruby.js'))
ace.config.setModuleUrl('ace/mode/ini', require('ace-builds/src-noconflict/mode-ini.js'))
ace.config.setModuleUrl('ace/mode/io', require('ace-builds/src-noconflict/mode-io.js'))
ace.config.setModuleUrl('ace/mode/ion', require('ace-builds/src-noconflict/mode-ion.js'))
ace.config.setModuleUrl('ace/mode/jack', require('ace-builds/src-noconflict/mode-jack.js'))
ace.config.setModuleUrl('ace/mode/jade', require('ace-builds/src-noconflict/mode-jade.js'))
ace.config.setModuleUrl('ace/mode/java', require('ace-builds/src-noconflict/mode-java.js'))
ace.config.setModuleUrl('ace/mode/javascript', require('ace-builds/src-noconflict/mode-javascript.js'))
ace.config.setModuleUrl('ace/mode/jexl', require('ace-builds/src-noconflict/mode-jexl.js'))
ace.config.setModuleUrl('ace/mode/json', require('ace-builds/src-noconflict/mode-json.js'))
ace.config.setModuleUrl('ace/mode/json5', require('ace-builds/src-noconflict/mode-json5.js'))
ace.config.setModuleUrl('ace/mode/jsoniq', require('ace-builds/src-noconflict/mode-jsoniq.js'))
ace.config.setModuleUrl('ace/mode/jsp', require('ace-builds/src-noconflict/mode-jsp.js'))
ace.config.setModuleUrl('ace/mode/jssm', require('ace-builds/src-noconflict/mode-jssm.js'))
ace.config.setModuleUrl('ace/mode/jsx', require('ace-builds/src-noconflict/mode-jsx.js'))
ace.config.setModuleUrl('ace/mode/julia', require('ace-builds/src-noconflict/mode-julia.js'))
ace.config.setModuleUrl('ace/mode/kotlin', require('ace-builds/src-noconflict/mode-kotlin.js'))
ace.config.setModuleUrl('ace/mode/latex', require('ace-builds/src-noconflict/mode-latex.js'))
ace.config.setModuleUrl('ace/mode/latte', require('ace-builds/src-noconflict/mode-latte.js'))
ace.config.setModuleUrl('ace/mode/less', require('ace-builds/src-noconflict/mode-less.js'))
ace.config.setModuleUrl('ace/mode/liquid', require('ace-builds/src-noconflict/mode-liquid.js'))
ace.config.setModuleUrl('ace/mode/lisp', require('ace-builds/src-noconflict/mode-lisp.js'))
ace.config.setModuleUrl('ace/mode/livescript', require('ace-builds/src-noconflict/mode-livescript.js'))
ace.config.setModuleUrl('ace/mode/logiql', require('ace-builds/src-noconflict/mode-logiql.js'))
ace.config.setModuleUrl('ace/mode/logtalk', require('ace-builds/src-noconflict/mode-logtalk.js'))
ace.config.setModuleUrl('ace/mode/lsl', require('ace-builds/src-noconflict/mode-lsl.js'))
ace.config.setModuleUrl('ace/mode/lua', require('ace-builds/src-noconflict/mode-lua.js'))
ace.config.setModuleUrl('ace/mode/luapage', require('ace-builds/src-noconflict/mode-luapage.js'))
ace.config.setModuleUrl('ace/mode/lucene', require('ace-builds/src-noconflict/mode-lucene.js'))
ace.config.setModuleUrl('ace/mode/makefile', require('ace-builds/src-noconflict/mode-makefile.js'))
ace.config.setModuleUrl('ace/mode/markdown', require('ace-builds/src-noconflict/mode-markdown.js'))
ace.config.setModuleUrl('ace/mode/mask', require('ace-builds/src-noconflict/mode-mask.js'))
ace.config.setModuleUrl('ace/mode/matlab', require('ace-builds/src-noconflict/mode-matlab.js'))
ace.config.setModuleUrl('ace/mode/maze', require('ace-builds/src-noconflict/mode-maze.js'))
ace.config.setModuleUrl('ace/mode/mediawiki', require('ace-builds/src-noconflict/mode-mediawiki.js'))
ace.config.setModuleUrl('ace/mode/mel', require('ace-builds/src-noconflict/mode-mel.js'))
ace.config.setModuleUrl('ace/mode/mips', require('ace-builds/src-noconflict/mode-mips.js'))
ace.config.setModuleUrl('ace/mode/mixal', require('ace-builds/src-noconflict/mode-mixal.js'))
ace.config.setModuleUrl('ace/mode/mushcode', require('ace-builds/src-noconflict/mode-mushcode.js'))
ace.config.setModuleUrl('ace/mode/mysql', require('ace-builds/src-noconflict/mode-mysql.js'))
ace.config.setModuleUrl('ace/mode/nasal', require('ace-builds/src-noconflict/mode-nasal.js'))
ace.config.setModuleUrl('ace/mode/nginx', require('ace-builds/src-noconflict/mode-nginx.js'))
ace.config.setModuleUrl('ace/mode/nim', require('ace-builds/src-noconflict/mode-nim.js'))
ace.config.setModuleUrl('ace/mode/nix', require('ace-builds/src-noconflict/mode-nix.js'))
ace.config.setModuleUrl('ace/mode/nsis', require('ace-builds/src-noconflict/mode-nsis.js'))
ace.config.setModuleUrl('ace/mode/nunjucks', require('ace-builds/src-noconflict/mode-nunjucks.js'))
ace.config.setModuleUrl('ace/mode/objectivec', require('ace-builds/src-noconflict/mode-objectivec.js'))
ace.config.setModuleUrl('ace/mode/ocaml', require('ace-builds/src-noconflict/mode-ocaml.js'))
ace.config.setModuleUrl('ace/mode/odin', require('ace-builds/src-noconflict/mode-odin.js'))
ace.config.setModuleUrl('ace/mode/partiql', require('ace-builds/src-noconflict/mode-partiql.js'))
ace.config.setModuleUrl('ace/mode/pascal', require('ace-builds/src-noconflict/mode-pascal.js'))
ace.config.setModuleUrl('ace/mode/perl', require('ace-builds/src-noconflict/mode-perl.js'))
ace.config.setModuleUrl('ace/mode/pgsql', require('ace-builds/src-noconflict/mode-pgsql.js'))
ace.config.setModuleUrl('ace/mode/php', require('ace-builds/src-noconflict/mode-php.js'))
ace.config.setModuleUrl('ace/mode/php_laravel_blade', require('ace-builds/src-noconflict/mode-php_laravel_blade.js'))
ace.config.setModuleUrl('ace/mode/pig', require('ace-builds/src-noconflict/mode-pig.js'))
ace.config.setModuleUrl('ace/mode/plain_text', require('ace-builds/src-noconflict/mode-plain_text.js'))
ace.config.setModuleUrl('ace/mode/plsql', require('ace-builds/src-noconflict/mode-plsql.js'))
ace.config.setModuleUrl('ace/mode/powershell', require('ace-builds/src-noconflict/mode-powershell.js'))
ace.config.setModuleUrl('ace/mode/praat', require('ace-builds/src-noconflict/mode-praat.js'))
ace.config.setModuleUrl('ace/mode/prisma', require('ace-builds/src-noconflict/mode-prisma.js'))
ace.config.setModuleUrl('ace/mode/prolog', require('ace-builds/src-noconflict/mode-prolog.js'))
ace.config.setModuleUrl('ace/mode/properties', require('ace-builds/src-noconflict/mode-properties.js'))
ace.config.setModuleUrl('ace/mode/protobuf', require('ace-builds/src-noconflict/mode-protobuf.js'))
ace.config.setModuleUrl('ace/mode/prql', require('ace-builds/src-noconflict/mode-prql.js'))
ace.config.setModuleUrl('ace/mode/puppet', require('ace-builds/src-noconflict/mode-puppet.js'))
ace.config.setModuleUrl('ace/mode/python', require('ace-builds/src-noconflict/mode-python.js'))
ace.config.setModuleUrl('ace/mode/qml', require('ace-builds/src-noconflict/mode-qml.js'))
ace.config.setModuleUrl('ace/mode/r', require('ace-builds/src-noconflict/mode-r.js'))
ace.config.setModuleUrl('ace/mode/raku', require('ace-builds/src-noconflict/mode-raku.js'))
ace.config.setModuleUrl('ace/mode/razor', require('ace-builds/src-noconflict/mode-razor.js'))
ace.config.setModuleUrl('ace/mode/rdoc', require('ace-builds/src-noconflict/mode-rdoc.js'))
ace.config.setModuleUrl('ace/mode/red', require('ace-builds/src-noconflict/mode-red.js'))
ace.config.setModuleUrl('ace/mode/redshift', require('ace-builds/src-noconflict/mode-redshift.js'))
ace.config.setModuleUrl('ace/mode/rhtml', require('ace-builds/src-noconflict/mode-rhtml.js'))
ace.config.setModuleUrl('ace/mode/robot', require('ace-builds/src-noconflict/mode-robot.js'))
ace.config.setModuleUrl('ace/mode/rst', require('ace-builds/src-noconflict/mode-rst.js'))
ace.config.setModuleUrl('ace/mode/ruby', require('ace-builds/src-noconflict/mode-ruby.js'))
ace.config.setModuleUrl('ace/mode/rust', require('ace-builds/src-noconflict/mode-rust.js'))
ace.config.setModuleUrl('ace/mode/sac', require('ace-builds/src-noconflict/mode-sac.js'))
ace.config.setModuleUrl('ace/mode/sass', require('ace-builds/src-noconflict/mode-sass.js'))
ace.config.setModuleUrl('ace/mode/scad', require('ace-builds/src-noconflict/mode-scad.js'))
ace.config.setModuleUrl('ace/mode/scala', require('ace-builds/src-noconflict/mode-scala.js'))
ace.config.setModuleUrl('ace/mode/scheme', require('ace-builds/src-noconflict/mode-scheme.js'))
ace.config.setModuleUrl('ace/mode/scrypt', require('ace-builds/src-noconflict/mode-scrypt.js'))
ace.config.setModuleUrl('ace/mode/scss', require('ace-builds/src-noconflict/mode-scss.js'))
ace.config.setModuleUrl('ace/mode/sh', require('ace-builds/src-noconflict/mode-sh.js'))
ace.config.setModuleUrl('ace/mode/sjs', require('ace-builds/src-noconflict/mode-sjs.js'))
ace.config.setModuleUrl('ace/mode/slim', require('ace-builds/src-noconflict/mode-slim.js'))
ace.config.setModuleUrl('ace/mode/smarty', require('ace-builds/src-noconflict/mode-smarty.js'))
ace.config.setModuleUrl('ace/mode/smithy', require('ace-builds/src-noconflict/mode-smithy.js'))
ace.config.setModuleUrl('ace/mode/snippets', require('ace-builds/src-noconflict/mode-snippets.js'))
ace.config.setModuleUrl('ace/mode/soy_template', require('ace-builds/src-noconflict/mode-soy_template.js'))
ace.config.setModuleUrl('ace/mode/space', require('ace-builds/src-noconflict/mode-space.js'))
ace.config.setModuleUrl('ace/mode/sparql', require('ace-builds/src-noconflict/mode-sparql.js'))
ace.config.setModuleUrl('ace/mode/sql', require('ace-builds/src-noconflict/mode-sql.js'))
ace.config.setModuleUrl('ace/mode/sqlserver', require('ace-builds/src-noconflict/mode-sqlserver.js'))
ace.config.setModuleUrl('ace/mode/stylus', require('ace-builds/src-noconflict/mode-stylus.js'))
ace.config.setModuleUrl('ace/mode/svg', require('ace-builds/src-noconflict/mode-svg.js'))
ace.config.setModuleUrl('ace/mode/swift', require('ace-builds/src-noconflict/mode-swift.js'))
ace.config.setModuleUrl('ace/mode/tcl', require('ace-builds/src-noconflict/mode-tcl.js'))
ace.config.setModuleUrl('ace/mode/terraform', require('ace-builds/src-noconflict/mode-terraform.js'))
ace.config.setModuleUrl('ace/mode/tex', require('ace-builds/src-noconflict/mode-tex.js'))
ace.config.setModuleUrl('ace/mode/text', require('ace-builds/src-noconflict/mode-text.js'))
ace.config.setModuleUrl('ace/mode/textile', require('ace-builds/src-noconflict/mode-textile.js'))
ace.config.setModuleUrl('ace/mode/toml', require('ace-builds/src-noconflict/mode-toml.js'))
ace.config.setModuleUrl('ace/mode/tsx', require('ace-builds/src-noconflict/mode-tsx.js'))
ace.config.setModuleUrl('ace/mode/turtle', require('ace-builds/src-noconflict/mode-turtle.js'))
ace.config.setModuleUrl('ace/mode/twig', require('ace-builds/src-noconflict/mode-twig.js'))
ace.config.setModuleUrl('ace/mode/typescript', require('ace-builds/src-noconflict/mode-typescript.js'))
ace.config.setModuleUrl('ace/mode/vala', require('ace-builds/src-noconflict/mode-vala.js'))
ace.config.setModuleUrl('ace/mode/vbscript', require('ace-builds/src-noconflict/mode-vbscript.js'))
ace.config.setModuleUrl('ace/mode/velocity', require('ace-builds/src-noconflict/mode-velocity.js'))
ace.config.setModuleUrl('ace/mode/verilog', require('ace-builds/src-noconflict/mode-verilog.js'))
ace.config.setModuleUrl('ace/mode/vhdl', require('ace-builds/src-noconflict/mode-vhdl.js'))
ace.config.setModuleUrl('ace/mode/visualforce', require('ace-builds/src-noconflict/mode-visualforce.js'))
ace.config.setModuleUrl('ace/mode/wollok', require('ace-builds/src-noconflict/mode-wollok.js'))
ace.config.setModuleUrl('ace/mode/xml', require('ace-builds/src-noconflict/mode-xml.js'))
ace.config.setModuleUrl('ace/mode/xquery', require('ace-builds/src-noconflict/mode-xquery.js'))
ace.config.setModuleUrl('ace/mode/yaml', require('ace-builds/src-noconflict/mode-yaml.js'))
ace.config.setModuleUrl('ace/mode/zeek', require('ace-builds/src-noconflict/mode-zeek.js'))

ace.config.setModuleUrl('ace/theme/ambiance', require('ace-builds/src-noconflict/theme-ambiance.js'))
ace.config.setModuleUrl('ace/theme/chaos', require('ace-builds/src-noconflict/theme-chaos.js'))
ace.config.setModuleUrl('ace/theme/chrome', require('ace-builds/src-noconflict/theme-chrome.js'))
ace.config.setModuleUrl('ace/theme/cloud9_day', require('ace-builds/src-noconflict/theme-cloud9_day.js'))
ace.config.setModuleUrl('ace/theme/cloud9_night', require('ace-builds/src-noconflict/theme-cloud9_night.js'))
ace.config.setModuleUrl('ace/theme/cloud9_night_low_color', require('ace-builds/src-noconflict/theme-cloud9_night_low_color.js'))
ace.config.setModuleUrl('ace/theme/cloud_editor', require('ace-builds/src-noconflict/theme-cloud_editor.js'))
ace.config.setModuleUrl('ace/theme/cloud_editor_dark', require('ace-builds/src-noconflict/theme-cloud_editor_dark.js'))
ace.config.setModuleUrl('ace/theme/clouds', require('ace-builds/src-noconflict/theme-clouds.js'))
ace.config.setModuleUrl('ace/theme/clouds_midnight', require('ace-builds/src-noconflict/theme-clouds_midnight.js'))
ace.config.setModuleUrl('ace/theme/cobalt', require('ace-builds/src-noconflict/theme-cobalt.js'))
ace.config.setModuleUrl('ace/theme/crimson_editor', require('ace-builds/src-noconflict/theme-crimson_editor.js'))
ace.config.setModuleUrl('ace/theme/dawn', require('ace-builds/src-noconflict/theme-dawn.js'))
ace.config.setModuleUrl('ace/theme/dracula', require('ace-builds/src-noconflict/theme-dracula.js'))
ace.config.setModuleUrl('ace/theme/dreamweaver', require('ace-builds/src-noconflict/theme-dreamweaver.js'))
ace.config.setModuleUrl('ace/theme/eclipse', require('ace-builds/src-noconflict/theme-eclipse.js'))
ace.config.setModuleUrl('ace/theme/github', require('ace-builds/src-noconflict/theme-github.js'))
ace.config.setModuleUrl('ace/theme/github_dark', require('ace-builds/src-noconflict/theme-github_dark.js'))
ace.config.setModuleUrl('ace/theme/gob', require('ace-builds/src-noconflict/theme-gob.js'))
ace.config.setModuleUrl('ace/theme/gruvbox', require('ace-builds/src-noconflict/theme-gruvbox.js'))
ace.config.setModuleUrl('ace/theme/gruvbox_dark_hard', require('ace-builds/src-noconflict/theme-gruvbox_dark_hard.js'))
ace.config.setModuleUrl('ace/theme/gruvbox_light_hard', require('ace-builds/src-noconflict/theme-gruvbox_light_hard.js'))
ace.config.setModuleUrl('ace/theme/idle_fingers', require('ace-builds/src-noconflict/theme-idle_fingers.js'))
ace.config.setModuleUrl('ace/theme/iplastic', require('ace-builds/src-noconflict/theme-iplastic.js'))
ace.config.setModuleUrl('ace/theme/katzenmilch', require('ace-builds/src-noconflict/theme-katzenmilch.js'))
ace.config.setModuleUrl('ace/theme/kr_theme', require('ace-builds/src-noconflict/theme-kr_theme.js'))
ace.config.setModuleUrl('ace/theme/kuroir', require('ace-builds/src-noconflict/theme-kuroir.js'))
ace.config.setModuleUrl('ace/theme/merbivore', require('ace-builds/src-noconflict/theme-merbivore.js'))
ace.config.setModuleUrl('ace/theme/merbivore_soft', require('ace-builds/src-noconflict/theme-merbivore_soft.js'))
ace.config.setModuleUrl('ace/theme/mono_industrial', require('ace-builds/src-noconflict/theme-mono_industrial.js'))
ace.config.setModuleUrl('ace/theme/monokai', require('ace-builds/src-noconflict/theme-monokai.js'))
ace.config.setModuleUrl('ace/theme/nord_dark', require('ace-builds/src-noconflict/theme-nord_dark.js'))
ace.config.setModuleUrl('ace/theme/one_dark', require('ace-builds/src-noconflict/theme-one_dark.js'))
ace.config.setModuleUrl('ace/theme/pastel_on_dark', require('ace-builds/src-noconflict/theme-pastel_on_dark.js'))
ace.config.setModuleUrl('ace/theme/solarized_dark', require('ace-builds/src-noconflict/theme-solarized_dark.js'))
ace.config.setModuleUrl('ace/theme/solarized_light', require('ace-builds/src-noconflict/theme-solarized_light.js'))
ace.config.setModuleUrl('ace/theme/sqlserver', require('ace-builds/src-noconflict/theme-sqlserver.js'))
ace.config.setModuleUrl('ace/theme/terminal', require('ace-builds/src-noconflict/theme-terminal.js'))
ace.config.setModuleUrl('ace/theme/textmate', require('ace-builds/src-noconflict/theme-textmate.js'))
ace.config.setModuleUrl('ace/theme/tomorrow', require('ace-builds/src-noconflict/theme-tomorrow.js'))
ace.config.setModuleUrl('ace/theme/tomorrow_night', require('ace-builds/src-noconflict/theme-tomorrow_night.js'))
ace.config.setModuleUrl('ace/theme/tomorrow_night_blue', require('ace-builds/src-noconflict/theme-tomorrow_night_blue.js'))
ace.config.setModuleUrl('ace/theme/tomorrow_night_bright', require('ace-builds/src-noconflict/theme-tomorrow_night_bright.js'))
ace.config.setModuleUrl('ace/theme/tomorrow_night_eighties', require('ace-builds/src-noconflict/theme-tomorrow_night_eighties.js'))
ace.config.setModuleUrl('ace/theme/twilight', require('ace-builds/src-noconflict/theme-twilight.js'))
ace.config.setModuleUrl('ace/theme/vibrant_ink', require('ace-builds/src-noconflict/theme-vibrant_ink.js'))
ace.config.setModuleUrl('ace/theme/xcode', require('ace-builds/src-noconflict/theme-xcode.js'))
ace.config.setModuleUrl('ace/mode/base_worker', require('ace-builds/src-noconflict/worker-base.js'))
ace.config.setModuleUrl('ace/mode/coffee_worker', require('ace-builds/src-noconflict/worker-coffee.js'))
ace.config.setModuleUrl('ace/mode/css_worker', require('ace-builds/src-noconflict/worker-css.js'))
ace.config.setModuleUrl('ace/mode/html_worker', require('ace-builds/src-noconflict/worker-html.js'))
ace.config.setModuleUrl('ace/mode/javascript_worker', require('ace-builds/src-noconflict/worker-javascript.js'))
ace.config.setModuleUrl('ace/mode/json_worker', require('ace-builds/src-noconflict/worker-json.js'))
ace.config.setModuleUrl('ace/mode/lua_worker', require('ace-builds/src-noconflict/worker-lua.js'))
ace.config.setModuleUrl('ace/mode/php_worker', require('ace-builds/src-noconflict/worker-php.js'))
ace.config.setModuleUrl('ace/mode/xml_worker', require('ace-builds/src-noconflict/worker-xml.js'))
ace.config.setModuleUrl('ace/mode/xquery_worker', require('ace-builds/src-noconflict/worker-xquery.js'))
ace.config.setModuleUrl('ace/mode/yaml_worker', require('ace-builds/src-noconflict/worker-yaml.js'))
ace.config.setModuleUrl('ace/snippets/abap', require('ace-builds/src-noconflict/snippets/abap.js'))
ace.config.setModuleUrl('ace/snippets/abc', require('ace-builds/src-noconflict/snippets/abc.js'))
ace.config.setModuleUrl('ace/snippets/actionscript', require('ace-builds/src-noconflict/snippets/actionscript.js'))
ace.config.setModuleUrl('ace/snippets/ada', require('ace-builds/src-noconflict/snippets/ada.js'))
ace.config.setModuleUrl('ace/snippets/alda', require('ace-builds/src-noconflict/snippets/alda.js'))
ace.config.setModuleUrl('ace/snippets/apache_conf', require('ace-builds/src-noconflict/snippets/apache_conf.js'))
ace.config.setModuleUrl('ace/snippets/apex', require('ace-builds/src-noconflict/snippets/apex.js'))
ace.config.setModuleUrl('ace/snippets/applescript', require('ace-builds/src-noconflict/snippets/applescript.js'))
ace.config.setModuleUrl('ace/snippets/aql', require('ace-builds/src-noconflict/snippets/aql.js'))
ace.config.setModuleUrl('ace/snippets/asciidoc', require('ace-builds/src-noconflict/snippets/asciidoc.js'))
ace.config.setModuleUrl('ace/snippets/asl', require('ace-builds/src-noconflict/snippets/asl.js'))
ace.config.setModuleUrl('ace/snippets/assembly_x86', require('ace-builds/src-noconflict/snippets/assembly_x86.js'))
ace.config.setModuleUrl('ace/snippets/astro', require('ace-builds/src-noconflict/snippets/astro.js'))
ace.config.setModuleUrl('ace/snippets/autohotkey', require('ace-builds/src-noconflict/snippets/autohotkey.js'))
ace.config.setModuleUrl('ace/snippets/batchfile', require('ace-builds/src-noconflict/snippets/batchfile.js'))
ace.config.setModuleUrl('ace/snippets/bibtex', require('ace-builds/src-noconflict/snippets/bibtex.js'))
ace.config.setModuleUrl('ace/snippets/c9search', require('ace-builds/src-noconflict/snippets/c9search.js'))
ace.config.setModuleUrl('ace/snippets/c_cpp', require('ace-builds/src-noconflict/snippets/c_cpp.js'))
ace.config.setModuleUrl('ace/snippets/cirru', require('ace-builds/src-noconflict/snippets/cirru.js'))
ace.config.setModuleUrl('ace/snippets/clojure', require('ace-builds/src-noconflict/snippets/clojure.js'))
ace.config.setModuleUrl('ace/snippets/cobol', require('ace-builds/src-noconflict/snippets/cobol.js'))
ace.config.setModuleUrl('ace/snippets/coffee', require('ace-builds/src-noconflict/snippets/coffee.js'))
ace.config.setModuleUrl('ace/snippets/coldfusion', require('ace-builds/src-noconflict/snippets/coldfusion.js'))
ace.config.setModuleUrl('ace/snippets/crystal', require('ace-builds/src-noconflict/snippets/crystal.js'))
ace.config.setModuleUrl('ace/snippets/csharp', require('ace-builds/src-noconflict/snippets/csharp.js'))
ace.config.setModuleUrl('ace/snippets/csound_document', require('ace-builds/src-noconflict/snippets/csound_document.js'))
ace.config.setModuleUrl('ace/snippets/csound_orchestra', require('ace-builds/src-noconflict/snippets/csound_orchestra.js'))
ace.config.setModuleUrl('ace/snippets/csound_score', require('ace-builds/src-noconflict/snippets/csound_score.js'))
ace.config.setModuleUrl('ace/snippets/csp', require('ace-builds/src-noconflict/snippets/csp.js'))
ace.config.setModuleUrl('ace/snippets/css', require('ace-builds/src-noconflict/snippets/css.js'))
ace.config.setModuleUrl('ace/snippets/curly', require('ace-builds/src-noconflict/snippets/curly.js'))
ace.config.setModuleUrl('ace/snippets/cuttlefish', require('ace-builds/src-noconflict/snippets/cuttlefish.js'))
ace.config.setModuleUrl('ace/snippets/d', require('ace-builds/src-noconflict/snippets/d.js'))
ace.config.setModuleUrl('ace/snippets/dart', require('ace-builds/src-noconflict/snippets/dart.js'))
ace.config.setModuleUrl('ace/snippets/diff', require('ace-builds/src-noconflict/snippets/diff.js'))
ace.config.setModuleUrl('ace/snippets/django', require('ace-builds/src-noconflict/snippets/django.js'))
ace.config.setModuleUrl('ace/snippets/dockerfile', require('ace-builds/src-noconflict/snippets/dockerfile.js'))
ace.config.setModuleUrl('ace/snippets/dot', require('ace-builds/src-noconflict/snippets/dot.js'))
ace.config.setModuleUrl('ace/snippets/drools', require('ace-builds/src-noconflict/snippets/drools.js'))
ace.config.setModuleUrl('ace/snippets/edifact', require('ace-builds/src-noconflict/snippets/edifact.js'))
ace.config.setModuleUrl('ace/snippets/eiffel', require('ace-builds/src-noconflict/snippets/eiffel.js'))
ace.config.setModuleUrl('ace/snippets/ejs', require('ace-builds/src-noconflict/snippets/ejs.js'))
ace.config.setModuleUrl('ace/snippets/elixir', require('ace-builds/src-noconflict/snippets/elixir.js'))
ace.config.setModuleUrl('ace/snippets/elm', require('ace-builds/src-noconflict/snippets/elm.js'))
ace.config.setModuleUrl('ace/snippets/erlang', require('ace-builds/src-noconflict/snippets/erlang.js'))
ace.config.setModuleUrl('ace/snippets/flix', require('ace-builds/src-noconflict/snippets/flix.js'))
ace.config.setModuleUrl('ace/snippets/forth', require('ace-builds/src-noconflict/snippets/forth.js'))
ace.config.setModuleUrl('ace/snippets/fortran', require('ace-builds/src-noconflict/snippets/fortran.js'))
ace.config.setModuleUrl('ace/snippets/fsharp', require('ace-builds/src-noconflict/snippets/fsharp.js'))
ace.config.setModuleUrl('ace/snippets/fsl', require('ace-builds/src-noconflict/snippets/fsl.js'))
ace.config.setModuleUrl('ace/snippets/ftl', require('ace-builds/src-noconflict/snippets/ftl.js'))
ace.config.setModuleUrl('ace/snippets/gcode', require('ace-builds/src-noconflict/snippets/gcode.js'))
ace.config.setModuleUrl('ace/snippets/gherkin', require('ace-builds/src-noconflict/snippets/gherkin.js'))
ace.config.setModuleUrl('ace/snippets/gitignore', require('ace-builds/src-noconflict/snippets/gitignore.js'))
ace.config.setModuleUrl('ace/snippets/glsl', require('ace-builds/src-noconflict/snippets/glsl.js'))
ace.config.setModuleUrl('ace/snippets/gobstones', require('ace-builds/src-noconflict/snippets/gobstones.js'))
ace.config.setModuleUrl('ace/snippets/golang', require('ace-builds/src-noconflict/snippets/golang.js'))
ace.config.setModuleUrl('ace/snippets/graphqlschema', require('ace-builds/src-noconflict/snippets/graphqlschema.js'))
ace.config.setModuleUrl('ace/snippets/groovy', require('ace-builds/src-noconflict/snippets/groovy.js'))
ace.config.setModuleUrl('ace/snippets/haml', require('ace-builds/src-noconflict/snippets/haml.js'))
ace.config.setModuleUrl('ace/snippets/handlebars', require('ace-builds/src-noconflict/snippets/handlebars.js'))
ace.config.setModuleUrl('ace/snippets/haskell', require('ace-builds/src-noconflict/snippets/haskell.js'))
ace.config.setModuleUrl('ace/snippets/haskell_cabal', require('ace-builds/src-noconflict/snippets/haskell_cabal.js'))
ace.config.setModuleUrl('ace/snippets/haxe', require('ace-builds/src-noconflict/snippets/haxe.js'))
ace.config.setModuleUrl('ace/snippets/hjson', require('ace-builds/src-noconflict/snippets/hjson.js'))
ace.config.setModuleUrl('ace/snippets/html', require('ace-builds/src-noconflict/snippets/html.js'))
ace.config.setModuleUrl('ace/snippets/html_elixir', require('ace-builds/src-noconflict/snippets/html_elixir.js'))
ace.config.setModuleUrl('ace/snippets/html_ruby', require('ace-builds/src-noconflict/snippets/html_ruby.js'))
ace.config.setModuleUrl('ace/snippets/ini', require('ace-builds/src-noconflict/snippets/ini.js'))
ace.config.setModuleUrl('ace/snippets/io', require('ace-builds/src-noconflict/snippets/io.js'))
ace.config.setModuleUrl('ace/snippets/ion', require('ace-builds/src-noconflict/snippets/ion.js'))
ace.config.setModuleUrl('ace/snippets/jack', require('ace-builds/src-noconflict/snippets/jack.js'))
ace.config.setModuleUrl('ace/snippets/jade', require('ace-builds/src-noconflict/snippets/jade.js'))
ace.config.setModuleUrl('ace/snippets/java', require('ace-builds/src-noconflict/snippets/java.js'))
ace.config.setModuleUrl('ace/snippets/javascript', require('ace-builds/src-noconflict/snippets/javascript.js'))
ace.config.setModuleUrl('ace/snippets/jexl', require('ace-builds/src-noconflict/snippets/jexl.js'))
ace.config.setModuleUrl('ace/snippets/json', require('ace-builds/src-noconflict/snippets/json.js'))
ace.config.setModuleUrl('ace/snippets/json5', require('ace-builds/src-noconflict/snippets/json5.js'))
ace.config.setModuleUrl('ace/snippets/jsoniq', require('ace-builds/src-noconflict/snippets/jsoniq.js'))
ace.config.setModuleUrl('ace/snippets/jsp', require('ace-builds/src-noconflict/snippets/jsp.js'))
ace.config.setModuleUrl('ace/snippets/jssm', require('ace-builds/src-noconflict/snippets/jssm.js'))
ace.config.setModuleUrl('ace/snippets/jsx', require('ace-builds/src-noconflict/snippets/jsx.js'))
ace.config.setModuleUrl('ace/snippets/julia', require('ace-builds/src-noconflict/snippets/julia.js'))
ace.config.setModuleUrl('ace/snippets/kotlin', require('ace-builds/src-noconflict/snippets/kotlin.js'))
ace.config.setModuleUrl('ace/snippets/latex', require('ace-builds/src-noconflict/snippets/latex.js'))
ace.config.setModuleUrl('ace/snippets/latte', require('ace-builds/src-noconflict/snippets/latte.js'))
ace.config.setModuleUrl('ace/snippets/less', require('ace-builds/src-noconflict/snippets/less.js'))
ace.config.setModuleUrl('ace/snippets/liquid', require('ace-builds/src-noconflict/snippets/liquid.js'))
ace.config.setModuleUrl('ace/snippets/lisp', require('ace-builds/src-noconflict/snippets/lisp.js'))
ace.config.setModuleUrl('ace/snippets/livescript', require('ace-builds/src-noconflict/snippets/livescript.js'))
ace.config.setModuleUrl('ace/snippets/logiql', require('ace-builds/src-noconflict/snippets/logiql.js'))
ace.config.setModuleUrl('ace/snippets/logtalk', require('ace-builds/src-noconflict/snippets/logtalk.js'))
ace.config.setModuleUrl('ace/snippets/lsl', require('ace-builds/src-noconflict/snippets/lsl.js'))
ace.config.setModuleUrl('ace/snippets/lua', require('ace-builds/src-noconflict/snippets/lua.js'))
ace.config.setModuleUrl('ace/snippets/luapage', require('ace-builds/src-noconflict/snippets/luapage.js'))
ace.config.setModuleUrl('ace/snippets/lucene', require('ace-builds/src-noconflict/snippets/lucene.js'))
ace.config.setModuleUrl('ace/snippets/makefile', require('ace-builds/src-noconflict/snippets/makefile.js'))
ace.config.setModuleUrl('ace/snippets/markdown', require('ace-builds/src-noconflict/snippets/markdown.js'))
ace.config.setModuleUrl('ace/snippets/mask', require('ace-builds/src-noconflict/snippets/mask.js'))
ace.config.setModuleUrl('ace/snippets/matlab', require('ace-builds/src-noconflict/snippets/matlab.js'))
ace.config.setModuleUrl('ace/snippets/maze', require('ace-builds/src-noconflict/snippets/maze.js'))
ace.config.setModuleUrl('ace/snippets/mediawiki', require('ace-builds/src-noconflict/snippets/mediawiki.js'))
ace.config.setModuleUrl('ace/snippets/mel', require('ace-builds/src-noconflict/snippets/mel.js'))
ace.config.setModuleUrl('ace/snippets/mips', require('ace-builds/src-noconflict/snippets/mips.js'))
ace.config.setModuleUrl('ace/snippets/mixal', require('ace-builds/src-noconflict/snippets/mixal.js'))
ace.config.setModuleUrl('ace/snippets/mushcode', require('ace-builds/src-noconflict/snippets/mushcode.js'))
ace.config.setModuleUrl('ace/snippets/mysql', require('ace-builds/src-noconflict/snippets/mysql.js'))
ace.config.setModuleUrl('ace/snippets/nasal', require('ace-builds/src-noconflict/snippets/nasal.js'))
ace.config.setModuleUrl('ace/snippets/nginx', require('ace-builds/src-noconflict/snippets/nginx.js'))
ace.config.setModuleUrl('ace/snippets/nim', require('ace-builds/src-noconflict/snippets/nim.js'))
ace.config.setModuleUrl('ace/snippets/nix', require('ace-builds/src-noconflict/snippets/nix.js'))
ace.config.setModuleUrl('ace/snippets/nsis', require('ace-builds/src-noconflict/snippets/nsis.js'))
ace.config.setModuleUrl('ace/snippets/nunjucks', require('ace-builds/src-noconflict/snippets/nunjucks.js'))
ace.config.setModuleUrl('ace/snippets/objectivec', require('ace-builds/src-noconflict/snippets/objectivec.js'))
ace.config.setModuleUrl('ace/snippets/ocaml', require('ace-builds/src-noconflict/snippets/ocaml.js'))
ace.config.setModuleUrl('ace/snippets/odin', require('ace-builds/src-noconflict/snippets/odin.js'))
ace.config.setModuleUrl('ace/snippets/partiql', require('ace-builds/src-noconflict/snippets/partiql.js'))
ace.config.setModuleUrl('ace/snippets/pascal', require('ace-builds/src-noconflict/snippets/pascal.js'))
ace.config.setModuleUrl('ace/snippets/perl', require('ace-builds/src-noconflict/snippets/perl.js'))
ace.config.setModuleUrl('ace/snippets/pgsql', require('ace-builds/src-noconflict/snippets/pgsql.js'))
ace.config.setModuleUrl('ace/snippets/php', require('ace-builds/src-noconflict/snippets/php.js'))
ace.config.setModuleUrl('ace/snippets/php_laravel_blade', require('ace-builds/src-noconflict/snippets/php_laravel_blade.js'))
ace.config.setModuleUrl('ace/snippets/pig', require('ace-builds/src-noconflict/snippets/pig.js'))
ace.config.setModuleUrl('ace/snippets/plain_text', require('ace-builds/src-noconflict/snippets/plain_text.js'))
ace.config.setModuleUrl('ace/snippets/plsql', require('ace-builds/src-noconflict/snippets/plsql.js'))
ace.config.setModuleUrl('ace/snippets/powershell', require('ace-builds/src-noconflict/snippets/powershell.js'))
ace.config.setModuleUrl('ace/snippets/praat', require('ace-builds/src-noconflict/snippets/praat.js'))
ace.config.setModuleUrl('ace/snippets/prisma', require('ace-builds/src-noconflict/snippets/prisma.js'))
ace.config.setModuleUrl('ace/snippets/prolog', require('ace-builds/src-noconflict/snippets/prolog.js'))
ace.config.setModuleUrl('ace/snippets/properties', require('ace-builds/src-noconflict/snippets/properties.js'))
ace.config.setModuleUrl('ace/snippets/protobuf', require('ace-builds/src-noconflict/snippets/protobuf.js'))
ace.config.setModuleUrl('ace/snippets/prql', require('ace-builds/src-noconflict/snippets/prql.js'))
ace.config.setModuleUrl('ace/snippets/puppet', require('ace-builds/src-noconflict/snippets/puppet.js'))
ace.config.setModuleUrl('ace/snippets/python', require('ace-builds/src-noconflict/snippets/python.js'))
ace.config.setModuleUrl('ace/snippets/qml', require('ace-builds/src-noconflict/snippets/qml.js'))
ace.config.setModuleUrl('ace/snippets/r', require('ace-builds/src-noconflict/snippets/r.js'))
ace.config.setModuleUrl('ace/snippets/raku', require('ace-builds/src-noconflict/snippets/raku.js'))
ace.config.setModuleUrl('ace/snippets/razor', require('ace-builds/src-noconflict/snippets/razor.js'))
ace.config.setModuleUrl('ace/snippets/rdoc', require('ace-builds/src-noconflict/snippets/rdoc.js'))
ace.config.setModuleUrl('ace/snippets/red', require('ace-builds/src-noconflict/snippets/red.js'))
ace.config.setModuleUrl('ace/snippets/redshift', require('ace-builds/src-noconflict/snippets/redshift.js'))
ace.config.setModuleUrl('ace/snippets/rhtml', require('ace-builds/src-noconflict/snippets/rhtml.js'))
ace.config.setModuleUrl('ace/snippets/robot', require('ace-builds/src-noconflict/snippets/robot.js'))
ace.config.setModuleUrl('ace/snippets/rst', require('ace-builds/src-noconflict/snippets/rst.js'))
ace.config.setModuleUrl('ace/snippets/ruby', require('ace-builds/src-noconflict/snippets/ruby.js'))
ace.config.setModuleUrl('ace/snippets/rust', require('ace-builds/src-noconflict/snippets/rust.js'))
ace.config.setModuleUrl('ace/snippets/sac', require('ace-builds/src-noconflict/snippets/sac.js'))
ace.config.setModuleUrl('ace/snippets/sass', require('ace-builds/src-noconflict/snippets/sass.js'))
ace.config.setModuleUrl('ace/snippets/scad', require('ace-builds/src-noconflict/snippets/scad.js'))
ace.config.setModuleUrl('ace/snippets/scala', require('ace-builds/src-noconflict/snippets/scala.js'))
ace.config.setModuleUrl('ace/snippets/scheme', require('ace-builds/src-noconflict/snippets/scheme.js'))
ace.config.setModuleUrl('ace/snippets/scrypt', require('ace-builds/src-noconflict/snippets/scrypt.js'))
ace.config.setModuleUrl('ace/snippets/scss', require('ace-builds/src-noconflict/snippets/scss.js'))
ace.config.setModuleUrl('ace/snippets/sh', require('ace-builds/src-noconflict/snippets/sh.js'))
ace.config.setModuleUrl('ace/snippets/sjs', require('ace-builds/src-noconflict/snippets/sjs.js'))
ace.config.setModuleUrl('ace/snippets/slim', require('ace-builds/src-noconflict/snippets/slim.js'))
ace.config.setModuleUrl('ace/snippets/smarty', require('ace-builds/src-noconflict/snippets/smarty.js'))
ace.config.setModuleUrl('ace/snippets/smithy', require('ace-builds/src-noconflict/snippets/smithy.js'))
ace.config.setModuleUrl('ace/snippets/snippets', require('ace-builds/src-noconflict/snippets/snippets.js'))
ace.config.setModuleUrl('ace/snippets/soy_template', require('ace-builds/src-noconflict/snippets/soy_template.js'))
ace.config.setModuleUrl('ace/snippets/space', require('ace-builds/src-noconflict/snippets/space.js'))
ace.config.setModuleUrl('ace/snippets/sparql', require('ace-builds/src-noconflict/snippets/sparql.js'))
ace.config.setModuleUrl('ace/snippets/sql', require('ace-builds/src-noconflict/snippets/sql.js'))
ace.config.setModuleUrl('ace/snippets/sqlserver', require('ace-builds/src-noconflict/snippets/sqlserver.js'))
ace.config.setModuleUrl('ace/snippets/stylus', require('ace-builds/src-noconflict/snippets/stylus.js'))
ace.config.setModuleUrl('ace/snippets/svg', require('ace-builds/src-noconflict/snippets/svg.js'))
ace.config.setModuleUrl('ace/snippets/swift', require('ace-builds/src-noconflict/snippets/swift.js'))
ace.config.setModuleUrl('ace/snippets/tcl', require('ace-builds/src-noconflict/snippets/tcl.js'))
ace.config.setModuleUrl('ace/snippets/terraform', require('ace-builds/src-noconflict/snippets/terraform.js'))
ace.config.setModuleUrl('ace/snippets/tex', require('ace-builds/src-noconflict/snippets/tex.js'))
ace.config.setModuleUrl('ace/snippets/text', require('ace-builds/src-noconflict/snippets/text.js'))
ace.config.setModuleUrl('ace/snippets/textile', require('ace-builds/src-noconflict/snippets/textile.js'))
ace.config.setModuleUrl('ace/snippets/toml', require('ace-builds/src-noconflict/snippets/toml.js'))
ace.config.setModuleUrl('ace/snippets/tsx', require('ace-builds/src-noconflict/snippets/tsx.js'))
ace.config.setModuleUrl('ace/snippets/turtle', require('ace-builds/src-noconflict/snippets/turtle.js'))
ace.config.setModuleUrl('ace/snippets/twig', require('ace-builds/src-noconflict/snippets/twig.js'))
ace.config.setModuleUrl('ace/snippets/typescript', require('ace-builds/src-noconflict/snippets/typescript.js'))
ace.config.setModuleUrl('ace/snippets/vala', require('ace-builds/src-noconflict/snippets/vala.js'))
ace.config.setModuleUrl('ace/snippets/vbscript', require('ace-builds/src-noconflict/snippets/vbscript.js'))
ace.config.setModuleUrl('ace/snippets/velocity', require('ace-builds/src-noconflict/snippets/velocity.js'))
ace.config.setModuleUrl('ace/snippets/verilog', require('ace-builds/src-noconflict/snippets/verilog.js'))
ace.config.setModuleUrl('ace/snippets/vhdl', require('ace-builds/src-noconflict/snippets/vhdl.js'))
ace.config.setModuleUrl('ace/snippets/visualforce', require('ace-builds/src-noconflict/snippets/visualforce.js'))
ace.config.setModuleUrl('ace/snippets/wollok', require('ace-builds/src-noconflict/snippets/wollok.js'))
ace.config.setModuleUrl('ace/snippets/xml', require('ace-builds/src-noconflict/snippets/xml.js'))
ace.config.setModuleUrl('ace/snippets/xquery', require('ace-builds/src-noconflict/snippets/xquery.js'))
ace.config.setModuleUrl('ace/snippets/yaml', require('ace-builds/src-noconflict/snippets/yaml.js'))
ace.config.setModuleUrl('ace/snippets/zeek', require('ace-builds/src-noconflict/snippets/zeek.js'))
