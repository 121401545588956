require('../css/adminlte.scss')
require('../css/fontawsome.scss')
require('../css/plugins.scss')

require('@popperjs/core')
window.bootstrap = require('bootstrap')
require('./admin-lte/AdminLTE')
require('./core/core')

console.log('Bootstrap 5 UI loaded')
